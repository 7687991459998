import React from "react";
import styles from "./ContactCard.module.scss";
import { contactAbbreviation } from "../../../utils";
import { IContactCard } from "./../../../constants/interfaces";

const ContactCard: React.FC<IContactCard> = ({ id, first_name, last_name, phone, email, fax, clicked }: any) => {
	return (
		<button className={styles.contact} onClick={clicked} key={id}>
			<span className={styles.contact_circle}>{contactAbbreviation(first_name, last_name, phone, email)}</span>
			<div className={styles.contact_name}>
				<span>{first_name + last_name ? first_name + " " + last_name : phone || email || fax}</span>
				{/* <SuitcaseIcon /> */}
			</div>
		</button>
	);
};

export default ContactCard;
